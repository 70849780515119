<template>
	<div>
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else class="display-flex gap-s">
			<div
				id="measurements-resolution-selector"
				class="display-flex grey3-bg font-bold p-xxs rounded-s grey1-color"
			>
				<div
					v-for="(data, index) in resolution_options"
					v-bind:class="{
						active: selected_resolution == data.key,
						'm-l-sm': index > 0,
						'm-r-sm': index < 3
					}"
					v-on:click="selected_resolution = data.key"
					class="rounded-s cursor-pointer flex-center-content"
				>
					<div>
						{{ data.label }}
					</div>
				</div>
			</div>

			<div
				class="grey3-bg"
				v-if="selected_dates_range && selected_resolution != 'yearly'"
			>
				<Datepicker
					v-if="datepicker_min_view == 'day'"
					v-model="selected_dates_range"
					:is_range="true"
					:max_range_days="6"
					:min_view="datepicker_min_view"
					:allow_from="datepicker_allow_from"
					:allow_to="datepicker_allow_to"
				/>
				<Datepicker
					v-else
					v-model="selected_dates_range"
					:min_view="datepicker_min_view"
					:allow_from="datepicker_allow_from"
					:allow_to="datepicker_allow_to"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Datepicker from "@form_components/Datepicker.vue";

import moment from "moment";

import AssetEnergyDataAvailableDateRangeFinderClass from "../../../../../../../src/asset-energy-data/application/asset-energy-data-available-date-range-finder.js";
const AssetEnergyDataAvailableDateRangeFinder =
	new AssetEnergyDataAvailableDateRangeFinderClass();

export default {
	name: "MeasurementsFilter",
	components: {
		Loader,
		Datepicker
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_energy_data_available_date_range: null,

			selected_resolution: "optimal",
			selected_dates_range: null,
			asset_has_energy_forecast_data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.$route.params.asset_id;

			this.asset_energy_data_available_date_range =
				await AssetEnergyDataAvailableDateRangeFinder.get_asset_energy_data_available_date_range(
					asset_id
				);
			this.asset_has_energy_forecast_data = this
				.asset_energy_data_available_date_range.energy_forecast.hourly
				? true
				: false;

			if (
				this.asset_energy_data_available_date_range.energy_measurements
					.hourly ||
				this.asset_energy_data_available_date_range.energy_forecast.hourly
			) {
				this.selected_resolution = "optimal";

				let selected_date_to = this.datepicker_allow_to;
				if (
					moment(selected_date_to).isAfter(new Date()) &&
					moment(selected_date_to).diff(new Date(), "days") > 4
				) {
					selected_date_to = moment().add(4, "days").toDate();
				}

				const selected_date_from = moment(selected_date_to)
					.subtract(this.asset_has_energy_forecast_data ? 5 : 3, "days")
					.toDate();

				this.selected_dates_range = [selected_date_from, selected_date_to];
			} else if (
				this.asset_energy_data_available_date_range.energy_measurements.monthly
			) {
				this.selected_resolution = "daily";

				this.selected_dates_range =
					this.asset_energy_data_available_date_range.energy_measurements.hourly.to;
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.loading = false;
		}
	},
	computed: {
		resolution_options() {
			return [
				{
					key: "optimal",
					label: this.$t("asset_energy_panel.day")
				},
				{
					key: "daily",
					label: this.$t("asset_energy_panel.month")
				},
				{
					key: "monthly",
					label: this.$t("asset_energy_panel.year")
				},
				{
					key: "yearly",
					label: this.$t("asset_energy_panel.total")
				}
			];
		},
		datepicker_min_view() {
			switch (this.selected_resolution) {
				case "optimal":
					return "day";
					break;
				case "daily":
					return "month";
					break;
				case "monthly":
					return "year";
					break;
			}
		},
		datepicker_allow_from() {
			if (!this.asset_energy_data_available_date_range) return null;

			switch (this.selected_resolution) {
				case "optimal":
					const energy_measurements_from =
						this.asset_energy_data_available_date_range.energy_measurements
							.hourly.from;
					if (
						!this.asset_energy_data_available_date_range.energy_forecast.hourly
					)
						return energy_measurements_from;

					const energy_forecast_from =
						this.asset_energy_data_available_date_range.energy_forecast.hourly
							.from;

					if (
						this.asset_has_energy_forecast_data &&
						moment(energy_measurements_from).isBefore(energy_forecast_from)
					)
						return energy_forecast_from;
					else return energy_measurements_from;
					break;
				case "daily":
					return this.asset_energy_data_available_date_range.energy_measurements
						.daily.from;
					break;
				case "monthly":
					return this.asset_energy_data_available_date_range.energy_measurements
						.monthly.from;
					break;
			}
		},
		datepicker_allow_to() {
			if (!this.asset_energy_data_available_date_range) return null;

			switch (this.selected_resolution) {
				case "optimal":
					const energy_measurements_to =
						this.asset_energy_data_available_date_range.energy_measurements
							.hourly.to;

					if (
						!this.asset_energy_data_available_date_range.energy_forecast.hourly
					)
						return energy_measurements_to;

					const energy_forecast_to =
						this.asset_energy_data_available_date_range.energy_forecast.hourly
							.to;

					if (
						this.asset_has_energy_forecast_data &&
						moment(energy_measurements_to).isBefore(energy_forecast_to)
					)
						return energy_forecast_to;
					else return energy_measurements_to;
					break;
				case "daily":
					return this.asset_energy_data_available_date_range.energy_measurements
						.daily.to;
					break;
				case "monthly":
					return this.asset_energy_data_available_date_range.energy_measurements
						.monthly.to;
					break;
			}
		}
	},
	methods: {
		emit_filter() {
			this.$emit(
				"filter_emited",
				this.no_data
					? { no_data: true }
					: {
							resolution: this.selected_resolution,
							date: this.selected_dates_range,
							asset_has_energy_forecast_data:
								this.asset_has_energy_forecast_data
					  }
			);
		}
	},
	watch: {
		selected_dates_range(new_value, old_value) {
			this.emit_filter();
		},
		selected_resolution(new_value, old_value) {
			if (old_value == "optimal")
				this.selected_dates_range = this.selected_dates_range[1];
			else if (new_value == "optimal") {
				let from;
				let to = this.selected_dates_range;

				if (moment(to).isAfter(this.datepicker_allow_to)) {
					to = this.datepicker_allow_to;
					from = moment(this.selected_dates_range).subtract(3, "days").toDate();
				} else if (moment(to).isBefore(this.datepicker_allow_from)) {
					to = this.datepicker_allow_from;
					from = to;
				}

				this.selected_dates_range = [from, to];
			}

			if (new_value == "yearly") this.emit_filter();
		}
	}
};
</script>

<style scoped>
#measurements-resolution-selector {
	border: 1px solid #ced4da;
}
#measurements-resolution-selector > div {
	padding: 0 8px;
}
#measurements-resolution-selector > div.active {
	background-color: var(--grey1);
	color: white;
}
</style>
